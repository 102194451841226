<div class="ai_test_analysis_root">
  <div class="ai_test_analysis_parent">
    <app-overlay-loader *ngIf="loader"></app-overlay-loader>
    <app-overlay-loader *ngIf="categoryLoader"></app-overlay-loader>
    <app-ai-loader *ngIf="aiLoader" [loaderText]="aiText"></app-ai-loader>
    <div class="test_analysis_content" *ngIf="!loader">
      <div class="good_performance" *ngIf="goodPerformer">
        <div class="course_dropdown">
          <p-dropdown [options]="courses" [(ngModel)]="selectedCourse" optionLabel="courseName" optionValue="courseID" (onChange)="getUserQuizzes()"></p-dropdown>
        </div>
        <img src="../../../../assets/images/AI_Test_Analysis/good_performance.svg">
        <h4>Congratulations!</h4>
        <h6>Your current score is outstanding! You can proceed to the Generate Quiz to further assess your progress.</h6>
        <div class="buttons">
          <button class="background" routerLink="/dashboard/generatequiz">Generate Quiz</button>
        </div>
      </div>
      <div class="no_quizzes" *ngIf="!aiLoader && quizCount <= 0 && !showUSMLECriteria && !testAnalysisEnabled && !goodPerformer">
        <div class="course_dropdown">
          <p-dropdown [options]="courses" [(ngModel)]="selectedCourse" optionLabel="courseName" optionValue="courseID" (onChange)="getUserQuizzes()"></p-dropdown>
        </div>
        <img src="../../../../assets/images/AI_Test_Analysis/no_progress.svg">
        <h4>No progress found</h4>
        <h6>To continue with AI Test Analysis, you need to attempt atleast one quiz from <span>”Generate Quiz”</span>. For more information, click on <span>USMLE Criteria</span>.</h6>
        <div class="buttons">
          <button class="background" routerLink="/dashboard/generatequiz">Generate Quiz</button>
          <button class="non_background" (click)="showUSMLECriteria = true;">USMLE Criteria</button>
        </div>
      </div>
      <div class="less_quizzes" *ngIf="!aiLoader && quizCount > 0 && !showUSMLECriteria && !testAnalysisEnabled && !goodPerformer">
        <div class="course_dropdown">
          <p-dropdown [options]="courses" [(ngModel)]="selectedCourse" optionLabel="courseName" optionValue="courseID" (onChange)="getUserQuizzes()"></p-dropdown>
        </div>
        <h4>Insufficient Data</h4>
        <h6>You don’t have enough solved quizzes. You need to attempt more quizzes from <span>”Generate Quiz”</span> in the following subjects to continue with AI Test Analysis.</h6>
        <button class="background" routerLink="/dashboard/generatequiz">Generate Quiz</button>
        <div class="subject_list">
          <div class="header">
            <h5>Subjects List</h5>
          </div>
          <div class="content">
            <div class="item" *ngFor="let subject of subjects">
              <div class="text">
                <span>{{ subject.QSubject }}</span>
              </div>
              <div class="count">
                <span>{{ subject.TotalQuestions }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-usmle-criteria *ngIf="showUSMLECriteria" (backToHome)="showUSMLECriteria = false"></app-usmle-criteria>
      <div class="test_analysis_intro" *ngIf="testAnalysisEnabled && !generateTestAnalysis">
        <img src="../../../../assets/images/AI_Test_Analysis/analysis_intro.svg">
        <h4>AI Test Analysis</h4>
        <h6>To begin with AI Test Analysis click on <span>Continue.</span></h6>
        <div class="buttons">
          <button class="background" (click)="generateAITestAnalysis()">Continue</button>
        </div>
      </div>
      <div class="analysis_content" *ngIf="generateTestAnalysis && testAnalysisEnabled">
        <div class="header">
          <div class="text">
            <h4>{{ usmleFeedback ? 'USMLE Test Feedback' : 'Improvements Required'}}</h4>
            <h6>{{ usmleFeedback ? 'AI detected some weak subjects from your USMLE test.' : 'Here is an AI-Generated study plan to boost your scores.'}}</h6>
          </div>
          <div class="buttons d-flex align-items-center">
            <p-dropdown [options]="courses" [(ngModel)]="selectedCourse" optionLabel="courseName" optionValue="courseID" (onChange)="getUserQuizzes()"></p-dropdown>
            <button class="gradient" (click)="allSubjects.length > 0 ? displayCategoryPopup = true : getCategoriesStatistics()">
              <img src="../../../../assets/images/ai_stars.svg">
              <span>Statistics by Category</span>
            </button>
          </div>
        </div>
        <div class="content">
          <div class="cards">
            <div class="graph_card">
              <div class="loader_progress">
                <div class="progress_bar">
                  <div class="loader_circle">
                    <div
                      role="progressbar"
                      class="progress_blue"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style="--value:{{ totalScore }}"
                    ></div>
                    <div class="loader_details flex-column align-items-center">
                      <h4
                        class="progress_text"
                      >
                        {{ totalScore }}%
                      </h4>
                      <h5>Your Score</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="graph_details">
                <div class="item">
                  <div class="box" style="background-color: #136376;"></div>
                  <span>Obtained Score</span>
                </div>
                <div class="item">
                  <div class="box" style="background-color: #EAECF0;"></div>
                  <span>Total Score</span>
                </div>
              </div>
            </div>
            <div class="text_card">
              <div class="header">
                <h5>Weak Areas</h5>
              </div>
              <hr>
              <div class="content">
                <span class="no_weak_areas" *ngIf="weakAreas.length <= 0">No weak areas found.</span>
                <div class="item" *ngFor="let area of weakAreas">
                  <div class="subject">
                    <div class="box" [ngStyle]="{ backgroundColor: (area.score >= 0 && area.score <= 40) ? '#EA0101' : (area.score > 40 && area.score < 90) ? '#389CF2' : '#2EA940' }"></div>
                    <span>{{ area.subject }}</span>
                    <span style="margin-left: 0.65rem;" [ngStyle]="{ color: (area.score >= 0 && area.score <= 40) ? '#EA0101' : (area.score > 40 && area.score < 90) ? '#389CF2' : '#2EA940' }">{{ area.score }}%</span>
                    <div class="info_icon">
                      <img src="../../../assets/images/performance_info.svg" width="14" style="margin-left: -0.2rem; margin-top: -0.3rem;"> 
                      <div class="tooltip">
                          <div class="beta_icon flex align-items-center">
                              <img src="../../../assets/images/ai_stars.svg">
                              <span>BETA</span>
                          </div>
                          <h4 [ngStyle]="{ color: (area.score >= 0 && area.score <= 40) ? '#EA0101' : (area.score > 40 && area.score < 90) ? '#389CF2' : '#2EA940' }"> {{ (area.score >= 0 && area.score <= 40) ? 'Weakness' : (area.score > 40 && area.score < 90) ? 'Average' : 'Strength' }}</h4>
                          <h5>Your performance statistics:</h5>
                          <h6><span>Correct Accuracy:</span> {{ area.correctAccuracy.toFixed(1) }}%</h6>
                          <h6><span>Average Time Spent per Question:</span> {{ area.timeSpent.toFixed(2) }} seconds</h6>
                      </div>
                    </div>
                  </div>
                  <div class="system" *ngFor="let system of area.systems">
                    <h6>{{ system }}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="performance_over_time">
            <div class="performance_graph">
              <div class="header">
                <h5>Performance Over Time</h5>
              </div>
              <hr>
              <div class="content">
                <p-chart type="line" [data]="graphData" width="100%" height="250px" [options]="graphOptions"></p-chart>
                <div class="text">
                  <div class="divider">
                    <header>
                      <div class="header__center">Performance Trends Across Quizzes</div>
                    </header>
                  </div>
                </div>
              </div>
            </div>
            <div class="performance_accuracy">
              <img [src]="performanceAccuracy.performance === 'good' ? '../../../../assets/images/good_performance.svg' : '../../../../assets/images/bad_performance.svg'">
              <h3>{{ performanceAccuracy.performance === 'good' ? 'Based on your AI Performance Analysis, your score has improved upto ' : 'Based on your AI Performance Analysis, your score has declined by ' }}<span [ngStyle]="{ color: performanceAccuracy.performance === 'good' ? '#2EA940' : '#EA0101' }">{{ performanceAccuracy.value.toFixed(1) + '%' }}</span></h3>
            </div>
          </div>
          <div class="custom_quiz" *ngIf="!usmleFeedback">
            <h3>Performance Prediction</h3>
            <!-- <h5 *ngIf="totalScore >= 90">You are all good! You can continue to USMLE test or stay here to further improve your score.</h5> -->
            <h5 *ngIf="totalScore < 100 && (studyPlanAvg < improvedStatistics?.percentage.toFixed(0) || (studyPlanAvg > improvedStatistics?.percentage.toFixed(0) && !isStudyPlanCompleted))">You can improve your score from <span style="color: #1F1F1F; font-weight: 600; font-size: 19px;">{{ totalScore }}%</span> to <span style="color: #2EA940; font-weight: 600; font-size: 19px;">{{ improvedStatistics?.score }}%</span> by achieving an average score of <span style="color: #3C3C42; font-weight: 600; opacity: 0.9;">{{ improvedStatistics?.percentage.toFixed(0) }}%</span> in your study plan.</h5>
            <h5 *ngIf="totalScore < 100 && studyPlanAvg > improvedStatistics?.percentage.toFixed(0) && isStudyPlanCompleted">You have improve your score to <span style="color: #2EA940; font-weight: 600; font-size: 19px;">{{ totalScore }}%</span> by achieving an average score of <span style="color: #3C3C42; font-weight: 600; opacity: 0.9;">{{ studyPlanAvg }}%</span> in your study plan.</h5>
            <!-- <div class="high_score" *ngIf="totalScore >= 90">
              <button class="gradient">
                <span (click)="generateUSMLECustomQuiz()">USMLE Test</span>
              </button>
            </div> -->
          </div>
          <!-- <div class="custom_quiz" *ngIf="usmleFeedback">
            <h5>This AI generated quiz will help you to improve your weak subject by <span style="color: #2EA940; font-weight: 600; font-size: 18px;">{{ improvedStatistics?.score }}%</span> if you mark {{ improvedStatistics?.questions }} correct answers. The quiz contains weak subjects: <span style="font-weight: 600;">{{ aiSubjects }}.</span></h5>
            <div class="low_score">
              <button class="gradient">
                <img src="../../../../assets/images/ai_stars.svg">
                <span (click)="generateAICustomQuiz(improvedStatistics.quizQuestions, improvedStatistics.quizQuestions.length)">Start Quiz</span>
              </button>
            </div>
          </div> -->
        </div>
      </div>
      <div class="study_plans" *ngIf="generateTestAnalysis && testAnalysisEnabled">
        <div class="header">
          <h4>Study Plan</h4>
        </div>
        <div class="content">
          <div class="quizzes">
            <div class="quiz" *ngFor="let quiz of studyPlanQuizzes; let i = index;" [ngClass]="i === 0 || (studyPlanQuizzes[i - 1]?.solved === true && !quiz.solved) || (studyPlanQuizzes[i - 1]?.solved === true && quiz.solved) ? '' : 'disabled'">
              <div class="quiz_content">
                <div class="score">
                  <img src="../../../../assets/images/quiz.png" *ngIf="!quiz.solved">
                  <h4 *ngIf="quiz.solved" [ngStyle]="{ color: (quiz.score >= 0 && quiz.score <= 40) ? '#EA0101' : (quiz.score > 40 && quiz.score < 90) ? '#389CF2' : '#2EA940' }">{{ quiz.score }}%</h4>
                  <h5 *ngIf="quiz.solved">Score</h5>
                </div>
                <div class="text">
                  <h4>Quiz # {{ i + 1 }}</h4>
                  <div class="statistics">
                    <h5>Questions: <span>{{ quiz.questions.length }}</span> ·</h5>
                    <h5 style="margin-left: 0.4rem;">Subjects: <span>{{ quiz.subjects.slice(0, 4) }}</span></h5>
                  </div>
                </div>
              </div>
              <div class="quiz_button" *ngIf="i === 0 || (studyPlanQuizzes[i - 1]?.solved === true && !quiz.solved) || (studyPlanQuizzes[i - 1]?.solved === true && quiz.solved)">
                <button class="gradient" (click)="generateAICustomQuiz(quiz)">
                  <img src="../../../../assets/images/ai_stars.svg">
                  <span>{{ quiz.solved ? 'Improve Score' : 'Start Quiz' }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-toast></p-toast>

<p-dialog header="Statistics by Category" [(visible)]="displayCategoryPopup" [modal]="true" [style]="{width: '800px'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <div class="statistics_content">
      <div class="options">
        <span *ngFor="let option of options; let i = index;" (click)="changeIndex(i)" [ngStyle]="{'color': i == activeIndex ? '#2196F3' : 'black', 'border-bottom': i == activeIndex ? '2px solid #2196F3' : 'none'}">{{option}}</span>
      </div>
      <hr class="horizontal_line">
      <div class="content">
        <div class="user_note">
          <h6><span>Note:</span> Your category statistics are sorted from strongest to weakest.</h6>
        </div>
        <div class="items">
          <div class="single_item" *ngFor="let item of (activeIndex === 0 ? allSubjects : activeIndex === 1 ? allSystems : allTopics)">
            <div class="info_icon popup_icon">
              <img src="../../../assets/images/performance_info.svg" width="14" style="margin-left: -0.2rem; margin-top: -0.3rem;"> 
              <div class="tooltip">
                  <div class="beta_icon flex align-items-center">
                      <img src="../../../assets/images/ai_stars.svg">
                      <span>BETA</span>
                  </div>
                  <h4 [ngStyle]="{ color: (item.CorrectAccuracy >= 0 && item.CorrectAccuracy <= 40) ? '#EA0101' : (item.CorrectAccuracy > 40 && item.CorrectAccuracy < 90) ? '#389CF2' : '#2EA940' }"> {{ (item.CorrectAccuracy >= 0 && item.CorrectAccuracy <= 40) ? 'Weakness' : (item.CorrectAccuracy > 40 && item.CorrectAccuracy < 90) ? 'Average' : 'Strength' }}</h4>
                  <h5>Your performance statistics:</h5>
                  <h6><span>Correct Accuracy:</span> {{ item.CorrectAccuracy }}%</h6>
                  <h6><span>Average Time Spent per Question:</span> {{ item.AvgTimeSpent }} seconds</h6>
              </div>
            </div>
            <h4>{{ item.Title }}</h4>
            <div class="icons">
              <img src="../../../assets/images/low_performance.svg" *ngIf="item.CorrectAccuracy >= 0 && item.CorrectAccuracy <= 50">
              <img src="../../../assets/images/high_performance.svg" *ngIf="item.CorrectAccuracy > 50 && item.CorrectAccuracy <= 100">
            </div>
          </div>
        </div>
      </div>
    </div>
</p-dialog>

<p-dialog header="Study Plan Completion" [(visible)]="displayStudyPlanPopup" [modal]="true" [style]="{width: '425px'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
  <div class="studyPlan">
    <img src="../../../../assets/images/completion.png">
    <div class="text" *ngIf="studyPlanAvg > improvedStatistics?.percentage.toFixed(0)">
      <h4>You have successfully completed your study plan with an average score of <span>{{ studyPlanAvg }}%</span>, which is <span style="color: #2EA940">higher</span> than the AI-recommended average score of <span>{{ improvedStatistics?.percentage.toFixed(0) }}%</span>. This has boosted your score to <span>{{ totalScore }}%</span>. </h4><h4>To continue with the new study plan, click <span style="cursor: pointer;" routerLink="/dashboard/generatequiz">"Generate Quiz"</span> to create a new quiz.</h4>
    </div>
    <div class="text" *ngIf="studyPlanAvg <= improvedStatistics?.percentage.toFixed(0)">
      <h4>You have successfully completed your study plan with an average score of <span>{{ studyPlanAvg }}%</span>, which is <span style="color: #EA0101">lower</span> than the AI-recommended average score of <span>{{ improvedStatistics?.percentage.toFixed(0) }}%</span>. </h4><h4>To achieve the AI-recommended score, consider improving your score in the study plan quizzes.</h4>
    </div>
  </div>
</p-dialog>