import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FlashcardService } from 'src/app/shared/services/flashcard.service';

import { ConfirmationService, MessageService } from 'primeng/api';
import * as Editor from '../../../assets/ckeditor/build/ckeditor';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss'],
  providers: [MessageService]
})
export class BrowseComponent implements OnInit {
  keyword = ""
  decks = []

  deck = {
    "userId": localStorage.getItem('Id'),
    "title": "",
    "color": "#62B5FC"
  }
  
  card = {
    "question": "",
    "answer": "",
    "tags": [],
    "deckId": -1,
    "questionId": -1
  }

  selectedDeck: any;
  selectedCard: any;
  deckActiveIndex = 0;
  cardActiveIndex = 0;
  deckId = 0
  cardId = 0

  deckColors = ['#62B5FC', '#F15856', '#FB7915', '#FFCC33', '#B13EDB',
                '#7274E1', '#6E756D', '#BF5D5D', '#FF8BF0', '#36C8A3'] 

  displayDeck = false
  displayCard = false
  displayDropdown = false
  displayFlashcards = false
  displayEditDeck = false
  displayAll = false
  displayCardOptions = false
  displayEditFlashcard = false
  displayRescheduleOptions = false

  searcher = false;
  searchedFlashcards = []

  isFullScreen = false;
  position = 'Front'

  dropdownEnabled = false;
  displayImg = false;
  showImage = '';

  flashcardView = 'Single Side View'

  reviewDays = 1
  rescheduleOption = 'New Card'

  ckEditorFront: any;
  ckEditorBack: any;

  frontEditor: any;
  backEditor: any;

  public ClassicEditor: any = Editor;

  isDemo = false;
  loader = false;

  @Input() courseId: any = null;
  @Input() aiDecks: any = false;
  @Input() aiDecksGenerated: any = false;
  @Output() aiDecksLength = new EventEmitter<number>();
  isReadyDecks = false;

  constructor(private flashcardService: FlashcardService, private confirmationService: ConfirmationService, private messageService: MessageService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if (localStorage.getItem('Id') == '-1') {
      this.isDemo = true
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isDemo) {
      this.decks = [
        {
          'CardReviews': 0,
          'Color': '#62B5FC',
          'DeckID': 1,
          'LastUsed': null,
          'Learning': 0,
          'NewCards': 0,
          'Title': 'Demo Notes',
          'ToReview': 0,
          'TotalTime': 0,
          'UserID': '-1',
          'Flashcards': [
            {
              'FlashcardID': 1,
              'Question': 'Free trial is for testing, saving text is available only for premium users',
              'Answer': '<p>Free trial is for testing, saving text is available only for premium users</p>',
              'DeckID': 1,
              'BuryDate': null,
              'Difficulty': null,
              'IsSuspended': 0,
              'Learning': 0,
              'Mastered': 0,
              'NextStudyDate': null,
              'QuestionID': null,
              'Solved': null,
              'Tags': ['Free Trial', 'Demo'],
              'TimeSpent': null,
              'UpdatedDate': null,
              'usedCard': null
            },
            {
              'FlashcardID': 2,
              'Question': 'Free trial is for testing, saving text is available only for premium users',
              'Answer': '<p>Free trial is for testing, saving text is available only for premium users</p>',
              'DeckID': 1,
              'BuryDate': null,
              'Difficulty': null,
              'IsSuspended': 0,
              'Learning': 0,
              'Mastered': 0,
              'NextStudyDate': null,
              'QuestionID': null,
              'Solved': null,
              'Tags': ['Free Trial', 'Demo'],
              'TimeSpent': null,
              'UpdatedDate': null,
              'usedCard': null
            }
          ]
        }
      ]
      if (this.decks.length > 0) {
        this.selectedDeck = this.decks[0]
      }
    } else {
      let isAIDecks = false;
      if (changes['aiDecksGenerated']) {
        if (changes['aiDecksGenerated'].currentValue) {
          this.getAIDecks();
        }
      }
      if (changes['courseId'] && this.aiDecks) {
        isAIDecks = true;
        this.isReadyDecks = true;
        this.getAIDecks();
      } else {
        if (changes['courseId'] && !isAIDecks) {
          if (changes['courseId'].currentValue) {
            if (this.courseId !== -1) {
              this.isReadyDecks = true;
              this.getReadyDecks()
            } else {
              this.getUserDeck()
            }
          }
        }
      }
    }
  }

  addUserDeck() {
    this.displayDeck = false
    if (this.isDemo) {
      this.decks.push({
        'CardReviews': 0,
        'Color': this.deck.color,
        'DeckID': this.decks.length + 1,
        'LastUsed': null,
        'Learning': 0,
        'NewCards': 0,
        'Title': this.deck.title,
        'ToReview': 0,
        'TotalTime': 0,
        'UserID': '-1',
        'Flashcards': []
      })
    } else {
      this.flashcardService.addUserDeck(this.deck).subscribe((res: any) => {
        if (!res.error) {
          this.deck.title = ""
          this.getUserDeck()
        }
        else {
          this.messageService.add({severity:'error', summary:'Add Deck Failed', detail:'Something went wrong while adding deck!'});
        }
      })
    }
  }

  addDeckFlashcard() {
    this.card.question = this.ckEditorFront.getData()
    this.card.answer = this.ckEditorBack.getData()
    this.card.deckId = this.selectedDeck.DeckID

    if (this.isDemo) {
      this.displayCard = false
      let index = this.decks.findIndex((item: any) => item.DeckID === this.card.deckId)
      this.decks[index].Flashcards.push({
        'FlashcardID': this.decks[index].Flashcards.length + 1,
        'Question': this.card.question,
        'Answer': this.card.answer,
        'DeckID': this.card.deckId,
        'BuryDate': null,
        'Difficulty': null,
        'IsSuspended': 0,
        'Learning': 0,
        'Mastered': 0,
        'NextStudyDate': null,
        'QuestionID': null,
        'Solved': null,
        'Tags': this.card.tags,
        'TimeSpent': null,
        'UpdatedDate': null,
        'usedCard': null
      })
    } else {
      this.flashcardService.addDeckFlashcard(this.card).subscribe((res: any) => {
        if (!res.error) {
          this.card.question = ""
          this.card.answer = ""
          this.card.tags = []
          this.card.deckId = -1
          this.displayCard = false
          this.getUserDeck()
        }
        else {
          this.messageService.add({severity:'error', summary:'Add Flashcard Failed', detail:'Something went wrong while adding flashcard!'});
        }
      })
    }
  }

  getAIDecks() {
    let data = {
      "userId": localStorage.getItem('Id'),
      "courseId": this.courseId
    }
    this.loader = true
    this.flashcardService.getAIDeck(data).subscribe((res: any) => {
      this.loader = false
      this.decks = res.data.decks
      this.decks.forEach((decks, index) => {
        this.decks[index].Flashcards.forEach((flashcards) => {
          if (flashcards.NextStudyDate == '0000-00-00 00:00:00') {
            flashcards.NextStudyDate = new Date()
          }
          flashcards.OriginalQuestion = flashcards.Question;
          flashcards.Tags = JSON.parse(flashcards.Tags)
          flashcards.Question = this.sanitizer.bypassSecurityTrustHtml(flashcards.Question)
        })
      })
      if (this.decks.length > 0) {
        this.selectedDeck = this.decks[0]
        this.aiDecksLength.emit(this.decks.length)
      }
    })
  }

  getReadyDecks() {
    let data = {
      "userId": localStorage.getItem('Id'),
      "courseId": this.courseId
    }
    this.loader = true
    this.flashcardService.getReadyDeck(data).subscribe((res: any) => {
      this.loader = false
      this.decks = res.data.decks
      this.decks.forEach((decks, index) => {
        this.decks[index].Flashcards.forEach((flashcards) => {
          if (flashcards.NextStudyDate == '0000-00-00 00:00:00') {
            flashcards.NextStudyDate = new Date()
          }
          flashcards.OriginalQuestion = flashcards.Question;
          flashcards.Tags = JSON.parse(flashcards.Tags)
          flashcards.Question = this.sanitizer.bypassSecurityTrustHtml(flashcards.Question)
        })
      })
      if (this.decks.length > 0) {
        this.selectedDeck = this.decks[0]
      }
    })
  }

  getUserDeck() {
    let data = {
      "userId": localStorage.getItem('Id')
    }
    this.loader = true
    this.flashcardService.getUserDeck(data).subscribe((res: any) => {
      this.loader = false
      this.decks = res.data.decks
      this.decks.forEach((decks, index) => {
        this.decks[index].Flashcards.forEach((flashcards) => {
          if (flashcards.NextStudyDate == '0000-00-00 00:00:00') {
            flashcards.NextStudyDate = new Date()
          }
          flashcards.OriginalQuestion = flashcards.Question;
          flashcards.Tags = JSON.parse(flashcards.Tags)
          flashcards.Question = this.sanitizer.bypassSecurityTrustHtml(flashcards.Question)
        })
      })
      if (!this.displayAll) {
        if (this.decks.length > 0) {
          this.selectedDeck = this.decks[0]
        }
      } else {
        const index = this.selectedDeck.deckIndex;
        this.selectedDeck = this.decks[index];
        this.selectedDeck.deckIndex = index;
      }

      if (sessionStorage.getItem('notebookFlashcards')) {
        const index = this.decks.findIndex((item: any) => item.DeckID == sessionStorage.getItem('notebookFlashcards'))
        sessionStorage.removeItem('notebookFlashcards')
        if (index !== -1) {
          if (this.decks[index].Flashcards.length > 0) {
            this.openFlashcard(this.decks[index].Flashcards[0], index, 0)
          }
        }
      }
    })
  }

  openFullscreen() {
    this.isFullScreen = true;
  }

  closeFullscreen() {
    this.isFullScreen = false;
  }

  closeModal() {
    this.displayCard = false
  }

  openCardDialog() {
    this.displayDropdown = false;
    this.displayCard = true;
    this.card.question = ""
    this.card.answer = ""
    this.card.tags = []

    if (this.ckEditorFront) {
      this.ckEditorFront.destroy()
    }
    if (this.ckEditorBack) {
      this.ckEditorBack.destroy()
    }

    setTimeout(() => {
      this.ClassicEditor.create(document.querySelector("#ckEditorFront"), {
        toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
      }).then((editor: any) => {
        this.ckEditorFront = editor
        editor.setData("")
      })

      this.ClassicEditor.create(document.querySelector("#ckEditorBack"), {
        toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
      }).then((editor: any) => {
        this.ckEditorBack = editor
        editor.setData("")
      })
    }, 100)
  }

  openFlashcard(card: any, deckIndex: any, cardIndex: any) {
    this.position = 'Front'
    this.displayFlashcards = true
    this.selectedCard = card
    this.deckActiveIndex = deckIndex
    this.cardActiveIndex = cardIndex
    this.flashcardView = 'Single Side View'
    this.setImageDisplay();
  }

  updatePosition(position: any) {
    this.position = position;
    this.setImageDisplay();
  }

  showAnswer() {
    this.position = 'Back';
    this.setImageDisplay();
  }

  openSearchFlashcard(card: any, cardIndex: any) {
    this.position = 'Front'
    this.displayFlashcards = true
    this.selectedCard = card
    this.deckActiveIndex = this.decks.findIndex((item => item.DeckID == card.DeckID))
    this.cardActiveIndex = cardIndex
    this.flashcardView = 'Single Side View'
  }

  prevCardIndex() {
    this.displayCardOptions = false
    if (this.cardActiveIndex > 0) {
      this.cardActiveIndex --
      this.position = 'Front'
    }
  }

  nextCardIndex() {
    this.displayCardOptions = false
    if (this.cardActiveIndex < this.decks[this.deckActiveIndex].Flashcards.length - 1) {
      this.cardActiveIndex ++
      this.position = 'Front'
    }
  }

  searchFlashcards() {
    if (this.keyword == "" || this.keyword.length < 3) {
      this.messageService.add({severity:'error', summary:'Error searching flashcards', detail:'Search keywords cannot be less than 3 characters.'});
    }
    else {
      this.searchedFlashcards = []
      this.decks.forEach((element) => {
        this.searchedFlashcards = [...this.searchedFlashcards, ...element.Flashcards]
      })

      let tempFlashcards = []
      this.searcher = true
      this.displayAll = false

      this.searchedFlashcards.forEach((element) => {  
        if ((element.OriginalQuestion.toLowerCase().includes(this.keyword.toLowerCase())) || (element.Answer.toLowerCase().includes(this.keyword.toLowerCase()))) {
          tempFlashcards.push(element)
        }
      });

      this.searchedFlashcards = tempFlashcards
    }
  }

  openDropdown(value: any) {
    this.decks.forEach((element, index) => {
      document.getElementById('dropdown'+index).style.display = 'none'
    })
    document.getElementById('dropdown'+value).style.display = 'flex'
    this.dropdownEnabled = true
  }
  
  closeDropdown(index: any) {
    document.getElementById('dropdown'+index).style.display = 'none'
    this.dropdownEnabled = false
  }

  openEditDialog(deck: any) {
    this.deck.title = deck.Title
    this.deck.color = deck.Color
    this.deckId = deck.DeckID
    this.displayEditDeck = true
  }

  openEditFlashcardDialog(flashcard: any) {
    this.card.tags = flashcard.Tags
    this.displayEditFlashcard = true
    this.displayFlashcards = false
    this.deckId = flashcard.DeckID
    this.cardId = flashcard.FlashcardID
    this.displayCardOptions = false

    if (this.ckEditorFront) {
      this.ckEditorFront.destroy()
    }
    if (this.ckEditorBack) {
      this.ckEditorBack.destroy()
    }

    setTimeout(() => {
      this.ClassicEditor.create(document.querySelector("#ckEditorFront"), {
        toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
      }).then((editor: any) => {
        this.ckEditorFront = editor
        editor.setData(flashcard.Question.changingThisBreaksApplicationSecurity)
      })

      this.ClassicEditor.create(document.querySelector("#ckEditorBack"), {
        toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
      }).then((editor: any) => {
        this.ckEditorBack = editor
        editor.setData(flashcard.Answer)
      })
    }, 100)
  }

  deleteUserDeck(deckId: any, flashcards: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this deck?',
      accept: () => {
        let data = {
          'deckId': deckId,
          'flashcardId': flashcards.map(item => item.FlashcardID),
          'userId': localStorage.getItem('Id')
        }
        if (this.isDemo) {
          let index = this.decks.findIndex((item: any) => item.DeckID === data.deckId)
          this.decks.splice(index, 1)
        } else {
          this.flashcardService.deleteUserDeck(data).subscribe((res: any) => {
            this.getUserDeck()
          })
        }
      },
      reject: () => {
      }
    })
  }

  deleteDeckFlashcard() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this flashcard?',
      accept: () => {
        let data = {
          'deckId': this.selectedCard.DeckID,
          'flashcardId': this.selectedCard.FlashcardID
        }
        if (this.isDemo) {
          this.displayFlashcards = false
          let index = this.decks.findIndex((item: any) => item.DeckID === data.deckId)
          let flashcardIndex = this.decks[index].Flashcards.findIndex((item: any) => item.FlashcardID === data.flashcardId)
          this.decks[index].Flashcards.splice(flashcardIndex, 1)
        } else {
          this.flashcardService.deleteDeckFlashcard(data).subscribe((res: any) => {
            this.displayFlashcards = false
            this.getUserDeck()
          })
        }
      },
      reject: () => {
      }
    })
  }

  editUserDeck() {
    let data = {
      'deckId': this.deckId,
      'title': this.deck.title,
      'color': this.deck.color
    }
    if (this.isDemo) {
      this.displayEditDeck = false
      let index = this.decks.findIndex((item: any) => item.DeckID === this.deckId)
      this.decks[index].Title = data.title
      this.decks[index].Color = data.color
    } else {
      this.flashcardService.editUserDeck(data).subscribe((res: any) => {
        this.displayEditDeck = false
        this.getUserDeck()
      })
    }
  }

  editDeckFlashcard() {
    let data = {
      'deckId': this.deckId,
      'question': this.ckEditorFront.getData(),
      'answer': this.ckEditorBack.getData(),
      'tags': this.card.tags,
      'flashcardId': this.cardId
    }
    if (this.isDemo) {
      this.displayEditFlashcard = false
      let index = this.decks.findIndex((item: any) => item.DeckID === this.deckId)
      let flashcardIndex = this.decks[index].Flashcards.findIndex((item: any) => item.FlashcardID === this.cardId)
      this.decks[index].Flashcards[flashcardIndex].Question = data.question
      this.decks[index].Flashcards[flashcardIndex].Answer = data.answer
      this.decks[index].Flashcards[flashcardIndex].Tags = data.tags
    } else {
      this.flashcardService.editDeckFlashcard(data).subscribe((res: any) => {
        this.displayEditFlashcard = false
        this.getUserDeck()
      })
    }
  }

  shuffleCards() {
    this.selectedDeck.Flashcards.sort((a: any, b: any) => 0.5 - Math.random())
  }

  seeAll(deck: any, deckIndex: any) {
    this.selectedDeck = deck;
    this.selectedDeck.deckIndex = deckIndex;
    this.displayAll = true;
  }

  changeView() {
    if (this.flashcardView == 'Single Side View') {
      this.flashcardView = 'Double Side View'
    }
    else {
      this.flashcardView = 'Single Side View'
    }
    this.setImageDisplay();
  }

  unsuspendFlashcard(deck: any, card: any) {
    let data = {
      "flashcardId": card,
      "deckId": deck,
      "userId": localStorage.getItem('Id')
    }
    this.flashcardService.unsuspendFlashcard(data).subscribe((res: any) => {
      this.messageService.add({severity:'success', summary:'Flashcard Status Updated', detail:'Your flashcard is unsuspended.'});

      this.displayCardOptions = false
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].IsSuspended = 0
    })
  }

  rescheduleFlashcard(deck: any, card: any) {
    let data = {
      "flashcardId": card,
      "deckId": deck,
      "userId": localStorage.getItem('Id'),
      "reviewDays": this.reviewDays,
      "rescheduleOption": this.rescheduleOption
    }
    this.flashcardService.rescheduleFlashcard(data).subscribe((res: any) => {
      this.messageService.add({severity:'success', summary:'Flashcard Status Updated', detail:'Your flashcard is rescheduled.'});
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].NextStudyDate = res.data.reviewDate
      this.displayRescheduleOptions = false
    })
  }

  setImageDisplay() {
    // Image Click Event //
    setTimeout(() => {
      document.querySelectorAll('.flashcard-editor-wrapper img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
    }, 500)
  }

  removeDisplayAll() {
    this.keyword = ''
    this.displayAll = false; 
    this.selectedDeck = this.decks[0]
  }
}
