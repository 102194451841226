import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { QuizServiceService } from 'src/app/shared/services/quiz-service.service';

@Component({
  selector: 'app-ai-test-analysis',
  templateUrl: './ai-test-analysis.component.html',
  styleUrls: ['./ai-test-analysis.component.scss'],
  providers: [MessageService]
})
export class AITestAnalysisComponent implements OnInit {
  totalScore = 0;
  weakAreas: any = []
  subjects = [];
  tempSubjects = [];
  quizCount = 0;
  testAnalysisEnabled = false;
  generateTestAnalysis = false;
  showUSMLECriteria = false;
  loader = false;
  aiLoader = false;
  aiText = 'AI Test Analysis';
  aiSubjects = [];
  improvedStatistics = null;
  usmleFeedback = false;
  goodPerformer = false;
  studyPlanQuizzes = []
  graphOptions: any;
  graphData: any;
  performanceAccuracy = '';

  constructor(private quizService: QuizServiceService, private router: Router, private messageService: MessageService) { }

  ngOnInit(): void {
    if (localStorage.getItem('USMLE_Test')) {
      this.usmleFeedback = true;
    }
    this.getUserQuizzes()
  }

  getUserQuizzes() {
    const data = {
      userId: localStorage.getItem('Id')
    }
    if (sessionStorage.getItem('AI_Analysis')) {
      this.aiLoader = true;
    } else {
      this.loader = true;
    }
    this.quizService.getUserQuizzes(data).subscribe((res: any) => {
      this.loader = false;
      this.quizCount = eval(res.subjects.map((item: any) => item.QuizCount).join('+'));
      this.subjects = res.subjects;
      this.tempSubjects = JSON.parse(JSON.stringify(this.subjects));

      // Insufficient Data Logic: //
      // If 3 subjects quizzes count is greater than 3 then user will be able to generate AI Test Analysis
      // If subjects quizzes count is less than 3 then those subjects will be displayed to the users so that they can generate more quizzes for those subjects

      let counter = 0;
      const updatedSubjects = [];
      this.subjects.forEach((element: any) => {
        if (element.QuizCount > 2) {
          counter ++;
        } else {
          updatedSubjects.push(element)
        }
      })
      if (counter >= 3) {
        this.testAnalysisEnabled = true;
      } else {
        this.aiLoader = false;
        sessionStorage.removeItem('AI_Analysis')
      }
  
      this.subjects = updatedSubjects;

      // -------------------------- //

      // If the user has solved the USMLE Mock Test, then AI Test Analysis will be calculated automatically to show the results //

      if (localStorage.getItem('USMLE_Test')) {
        localStorage.removeItem('USMLE_Test');
      }

      if (sessionStorage.getItem('AI_Analysis') && this.testAnalysisEnabled) {
        this.generateAITestAnalysis(false);
      }
    });
  }

  generateAITestAnalysis(regenerate: boolean) {
    const data = {
      userId: localStorage.getItem('Id'),
      subjects: this.tempSubjects
    }
    this.aiLoader = true;
    this.quizService.generateAITestAnalysis(data).subscribe((res: any) => {
      sessionStorage.setItem('AI_Analysis', JSON.stringify(true))
      this.aiLoader = false;
      this.totalScore = res.totalScore;
      this.weakAreas = res.weakAreas;
      this.aiSubjects = this.weakAreas.map((item: any) => item.subject);
      this.improvedStatistics = res.improvedStatistics;
      this.studyPlanQuizzes = res.studyPlanQuizzes;
      this.generateTestAnalysis = true;

      if (res.systems.length > 0) {
        for (let i = 0; i < res.systems.length; i++) {
          this.weakAreas.forEach((element: any) => {
            if (element.solvedQuestions.map((item: any) => item.QuestionID).includes(res.systems[i].ID)) {
              element.systems.push(res.systems[i].QSystem)
            }
          })
        }

        this.weakAreas.forEach((element: any) => {
          element.systems = [...new Set(element.systems)]
          element.systems = element.systems.slice(0, 3)
        })
      }

      // If the total score is greater than 95, then good performance screen will be displayed - Good Performance Case //

      if (this.totalScore >= 95) {
        this.goodPerformer = true;
        this.generateTestAnalysis = false;
        this.testAnalysisEnabled = false;
      }

      // Performance Over Time Graph //

      const userQuizzes = res.userQuizzes;
      this.performanceAccuracy = res.performanceAccuracy;

      this.graphOptions = {
        legend: { 
          display: false
        },
        scales: {
          xAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              display: true,
              drawOnChartArea: false,
              drawBorder: true
            }
          }],
          yAxes: [{
            ticks: {
              display: true
            },
            gridLines: {
              display: true,
              drawOnChartArea: false,
              drawBorder: true
            }
          }]
        },
      }

      this.graphData = {
        labels: userQuizzes.map((item: any) => item.QuizID),
        datasets: [
          {
            label: 'Score',
            data: userQuizzes.map((item: any) => item.Score),
            fill: false,
            borderColor: '#E0ECFE'
          }
        ]
      }

      if (regenerate) {
        this.messageService.add({severity:'success', summary:'AI Test Analysis', detail:'Your stats are up-to-date.'});
      }
    })
  }

  generateAICustomQuiz(quiz: any) {
    const data = {
      userId: localStorage.getItem('Id'),
      questionIds: quiz.questions,
      count: quiz.questions.length
    }
    this.loader = true;
    this.quizService.generateAICustomQuiz(data).subscribe((res: any) => {
      this.loader = false;

      if (res.status) {
        var realFlaggedQuestions = [];
        localStorage.setItem('viewMode', JSON.stringify(false));
        localStorage.setItem('QuizId', res.quizId);
        localStorage.setItem(
          'flaggedQuestion',
          JSON.stringify(realFlaggedQuestions)
        );
        localStorage.setItem('quizTitle', 'Custom AI Quiz ' + new Date().toISOString());
        localStorage.setItem(
          'AllQuestions',
          JSON.stringify(res.questions)
        );
        localStorage.setItem('studyPlanID', quiz.studyPlanID);

        const arr = ['SelectedOptionArray', 'SubmittedQuestions', 'singleQuestionTime', 'currentQuestionIndex', 'CurrentIndex', 'dotQuestionsArray', 'quiz_time', 'timesup', 'AnswerChanges', 'highlightedQuestionIndexes', 'qbankStatistics', 'subjectStatistics', 'systemStatistics', 'topicStatistics'];
        arr.forEach(i => {
          localStorage.removeItem(i);
        });
        this.router.navigate(['/quiz']);
      }
    })
  }

  // generateUSMLECustomQuiz() {
  //   const data = {
  //     userId: localStorage.getItem('Id')
  //   }
  //   this.loader = true;
  //   this.quizService.generateUSMLECustomQuiz(data).subscribe((res: any) => {
  //     if (res.quizQuestions.length > 0) {
  //       this.generateAICustomQuiz(res.quizQuestions, res.quizQuestions.length);
  //       localStorage.setItem('USMLE_Test', JSON.stringify(true));
  //     }
  //   })
  // }
}
