import {Component, Inject, Input, OnDestroy, OnInit, Renderer2, Injectable, RendererFactory2 } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

    private renderer: Renderer2;
    script;
    userXplorerScript: any = null;

    constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document, private router: Router) {
        this.script = ""
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    tawkIO(username: any, email: any, currenturl = this.router.url){

      if (username == null) {
        username = localStorage.getItem('UserName')
      }
      if (email == null) {
        email = localStorage.getItem('email')
      }


      // this.script = this.renderer.createElement('script');
      // this.script.text = `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      // Tawk_API.onLoad = function(){
      //   Tawk_API.hideWidget();
      //   Tawk_API.setAttributes({
      //     'name'  : "${username}",
      //     'email' :  "${email}",
      //   }, function(error){});
      // };
      // (function(){
      // var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      // s1.async=true;
      // s1.src='https://embed.tawk.to/6357d233b0d6371309cb6d24/1gg7gq6mh';
      // s1.charset='UTF-8';
      // s1.setAttribute('crossorigin','*');
      // s0.parentNode.insertBefore(s1,s0);
      // })();`;
      // this.renderer.appendChild(this.document.body, this.script);

      // this.zendeskVisitor(username, email, currenturl)

      // if (!this.userXplorerScript) {
      //   this.scriptRunner(username, email)
      // }
    }

    scriptRunner(username: any, email: any) {
      this.script = this.renderer.createElement('script');
      this.script.text = `
      var usrxp = usrxp || {}
      usrxp.username = '${username}';
      usrxp.email = '${email}';
      usrxp.API_KEY = '35dd6398-4463-46df-a115-0e261b9aa834';
      usrxp.website = 'auEYgyaKTHTsEqQgojUyMd';
      (function(){
        var s2=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s2.async=true;
        s2.src='https://cdnjs.cloudflare.com/ajax/libs/socket.io/4.6.1/socket.io.js';
        s2.charset='UTF-8';
        s0.parentNode.insertBefore(s2,s0);
        setTimeout(() => {
          var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
          s1.async=true;
          s1.src='https://staging.userxplorer.com/assets/script.js';
          s1.charset='UTF-8';
          s0.parentNode.insertBefore(s1,s0);
        }, 1000)
      })();`;
    
      this.userXplorerScript = this.renderer.appendChild(this.document.body, this.script);
    }

    zendeskVisitor(username: any, email: any, currenturl) {
      if (username == null) {
        username = localStorage.getItem('UserName')
      }
      if (email == null) {
        email = localStorage.getItem('email')
      }

      this.script = this.renderer.createElement('script');

      this.script.text = `$zopim(function(){$zopim.livechat.set({name: "${username}", email:  "${email}"}); });`

      this.renderer.appendChild(this.document.body, this.script);
      this.zendeskVisitorPath(currenturl);
    }

    zendeskVisitorPath(Currenturl) {
      this.script = this.renderer.createElement('script');
      this.script.text = `$zopim(function() {
          $zopim.livechat.sendVisitorPath({
            url: '${Currenturl}',
            title: '${Currenturl}'
          });
        });`
      this.renderer.appendChild(this.document.body, this.script);
    }



}
