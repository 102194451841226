import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminService } from '../shared/services/admin.service';
import { ChatService } from '../shared/services/chat.service';
import * as RecordRTC from 'recordrtc';
import { Subscription } from 'rxjs';
import crypto from 'crypto-js'

@Component({
  selector: 'app-chat-module',
  templateUrl: './chat-module.component.html',
  styleUrls: ['./chat-module.component.scss']
})
export class ChatModuleComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  search: any;
  messages = [];
  record: any;
  recording = false;
  url: any;
  error: any;
  recordingStream: any;
  userMessage = ''
  userId: any;
  chats: any;
  name = 'user';
  chatId: any;
  newMessage: any;
  firstUser = false
  tempChats: any = []
  loadingMessages = false;

  constructor(public datepipe: DatePipe,private adminService: AdminService, private chatService: ChatService, private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem('Id');
    this.getAllChats();
    this.chatService.shareData()
    this.getIntervalChats()

    setTimeout(() => {
      document.getElementById('text_chat').addEventListener('keypress', function (e) {
        if (e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault();
          return false;
        }
      })
    }, 100)
  }

  // This function is used to scroll the user chat to the bottom //
  scrollToBottom(smooth: boolean = false): void {
    try {
      if (this.myScrollContainer) {
        const scrollContainer = this.myScrollContainer.nativeElement;
        requestAnimationFrame(() => {
          if (smooth) {
            scrollContainer.scrollTo({
              top: scrollContainer.scrollHeight,
              behavior: 'smooth',
            });
          } else {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
          }
        });
      }
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }

  getIntervalChats() {
    this.chatService.getShareMessage().subscribe(async (data: any) => {
      if (data) {
        await this.updateUnreadMessages()
        this.subscriptions.push(
          this.adminService.getAllChats(localStorage.getItem('Id')).subscribe((res: any) => {
            for (let i = 0; i < res.data.chats.length; i++) {
              for (let j = 0; j < this.chats.length; j++) {
                if (res.data.chats[i].UserID == this.chats[j].UserID && res.data.chats[i].UserID !== this.chatId) {
                  this.chats[j].unreadMessages = JSON.parse(res.data.chats[i].Messages)
                  let messages = JSON.parse(res.data.chats[i].Messages)
                  var length = messages.length - 1;
                  this.chats[j].latestMessage = messages[length].message;
                  this.chats[j].latestType =  messages[length].type;
                  this.chats[j].latestDate =  messages[length].date;
                }
              }
            }
    
            res.data.chats.splice(res.data.chats.findIndex((item: any) => item.UserID == this.chatId), 1)
    
            for (let i = 0; i < res.data.chats.length; i++) {
              for (let j = 0; j < this.chats.length; j++) {
                if (res.data.chats[i].UserID == this.chats[j].UserID) {
                  if (JSON.parse(res.data.chats[i].Messages).length - this.chats[j].Messages.length > 0) {
                    this.chats[j].latestMessages = JSON.parse(res.data.chats[i].Messages).length - this.chats[j].Messages.length
                  }
                  break;
                }
              }
            }
          })
        )
      }
    })
  }

  getAllChats(){
    this.adminService.getAllChats(localStorage.getItem('Id')).subscribe((res: any) => {
      var chats = res.data.chats;
      for(let i=0; i<chats.length; i++){
        chats[i].profilePicture = '../.././assets/images/default.jpeg';
        chats[i].Messages = chats[i].Messages.replace(/[\\]/g, '').replace(/[\t]/g, '').replace(/[\n]/g, '').replace(/[\r]/g, '')
        chats[i].Messages = JSON.parse(chats[i].Messages)
        chats[i].Messages = chats[i].Messages.filter((item: any) => item.userId != -2);
        chats[i].Messages = chats[i].Messages.filter((item: any) => !item.askAIBot);
        var length = chats[i].Messages.length-1;
        chats[i].latestMessage =  chats[i].Messages[length].message;
        chats[i].latestType =  chats[i].Messages[length].type;
        chats[i].latestDate =  chats[i].Messages[length].date;
        chats[i].name = chats[i].Messages[0].name;
        chats[i].latestMessages = chats[i].UnreadMessages
        chats[i].unreadMessages = chats[i].Messages
      }
      this.chats = chats;
      this.tempChats = JSON.parse(JSON.stringify(this.chats))
      if (this.chats.length > 0) {
        this.getNewChat(chats[0])
      }
    })
  }

  onSearchChange(event: any) {
    this.chats = this.tempChats.filter((item)=>{
      return item.name.toLowerCase().includes(event.target.value.toLowerCase())
    })
  }

  async getNewChat(user: any){
    this.loadingMessages = true;
    if (this.firstUser) {
      this.chatService.closeChat()
    }
    user.latestMessages = 0
    this.name = user.Messages[0].name;
    this.chatId = user.UserID

    const chat: any = await this.getUserChat();
    await this.updateUnreadMessages();

    if (chat.Messages) {
      chat.Messages = chat.Messages.replace(/[\\]/g, '')
        .replace(/[\t]/g, '')
        .replace(/[\n]/g, '')
        .replace(/[\r]/g, '');
      this.messages = JSON.parse(chat.Messages);
    }
    this.scrollToBottom(true);
    this.chatService.startChat(this.chatId)
    this.connectUserChat();
    await this.updateUnreadMessages()
    this.loadingMessages = false;
    this.firstUser = true
    setTimeout(() => {
      this.scrollToBottom(true);
    }, 10)
  }

  updateUnreadMessages() {
    return new Promise((resolve, reject) => {
      let data = {
        "userId": this.chatId
      }
      this.chatService.updateUnreadMessages(data).subscribe((res: any) => {
        resolve(res)
      })
    })
  }

  // This function is used to connect the user chat with the socket for real-time communication //
  connectUserChat() {
    this.subscriptions.push(
      this.chatService.getNewMessage().subscribe((data: any) => {
        if (data) {
          // Logic for decrypting the message received for secure transmission //
          let bytes = crypto.AES.decrypt(
            data.message,
            'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3'
          );
          data.message = bytes.toString(crypto.enc.Utf8);

          if (data.userId == -2) {
            const message = {
              userId: data.userId,
              name: this.name,
              message: data.message,
              date: new Date().toISOString(),
              type: data.type,
              isAIBot: true,
              askAIBot: false,
              askAdmin: false
            };

            if (data.message) {
              this.messages.push(message);
            } else {
              this.messages[this.messages.length - 1].askAdmin = true;
            }

            this.scrollToBottom(true);
          } else {
            if (data.message) {
              if (data.userId == data.roomName) {
                const message = {
                  userId: data.userId,
                  name: this.name,
                  message: data.message,
                  date: new Date().toISOString(),
                  type: data.type,
                };
    
                this.messages.push(message);
    
                // Logic for updating the latest message in the users list //
                let index = this.chats.findIndex(
                  (item: any) =>
                    item.UserID == data.userId
                );
                if (index !== -1) {
                  this.chats[index].latestMessage = message.message;
                  this.chats[index].latestType = message.type;
                  this.chats[index].latestDate = message.date;
                }
    
              } else {
                // Logic for updating the latest message in the users list //
                let index = this.chats.findIndex(
                  (item: any) =>
                    item.UserID == this.chatId
                );
                if (index !== -1) {
                  this.chats[index].latestMessage = data.message;
                  this.chats[index].latestType = data.type;
                  this.chats[index].latestDate = new Date().toISOString();
                }
    
              }
            } else {
              this.messages[this.messages.length - 1].askAdmin = true;
            }
            this.scrollToBottom(true);
          }
        }
      })
    );
  }

  // This function is used to get specific user chat from the database //
  getUserChat() {
    return new Promise((resolve: any) => {
      this.chatService.getSingleUserChat(this.chatId).subscribe({
        next: (res: any) => {
          resolve(res.data.chat);
        },
        error: (error) => {
          console.error('Error fetching chats:', error);
        },
      });
    });
  }

  sendNewMessage() {
    const message = this.userMessage;
    this.userMessage = '';
    var date = new Date()
    var data = {
      userId: localStorage.getItem("Id"),
      name: localStorage.getItem('UserName'),
      message: message,
      type: 'text',
      date: date,
      isAIBot: false,
      askAIBot: false,
      askAdmin: false
    }
    this.messages.push(data);
    var newdata = {
      userId: this.chatId,
      messages: this.messages,
      currentUser: localStorage.getItem("Id")
    }

    this.chatService.sendMessage({message: message, type: 'text', roomName: this.chatId, userId: localStorage.getItem("Id"), data: newdata}, cb => {
      console.log("ACKNOWLEDGEMENT ", cb);
    });
  }

  onFileUpload(event:any){
    const formData = new FormData();
    formData.append('chatDocument', event.target.files[0]);
    this.chatService.uploadChatDocument(formData).subscribe((res: any) => {
      var date = new Date()
      var data = {
        userId: localStorage.getItem("Id"),
        name: localStorage.getItem('UserName'),
        message: res.data.chatFile,
        date: date,
        type: 'file'
      }
      this.messages.push(data);
      var newdata = {
        userId: this.chatId,
        messages: this.messages,
        currentUser: localStorage.getItem("Id")
      }

      this.chatService.sendMessage({message: res.data.chatFile, type: 'file', roomName: this.chatId, userId: localStorage.getItem("Id"), data: newdata}, cb => {
        console.log("ACKNOWLEDGEMENT ", cb);
      });
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
    this.chatService.closeChat()
    this.chatService.closeShareData()
    this.stopAudio(this.recordingStream)
  }

  // Audio File Implementation //

  initiateRecording() {
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  successCallback(stream: any) {
    this.recordingStream = stream
    var options = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1,
      sampleRate: 50000,
    };
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }

  stopRecording() {
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
  }

  processRecording(blob: any) {
    this.url = URL.createObjectURL(blob);
    this.sendAudioFile(blob);
  }

  errorCallback(error: any) {
    this.error = 'Can not play audio in your browser';  
  }

  stopAudio(stream: any) {
    if (stream) {
      for (let i = 0; i < stream.getTracks().length; i++) {
        stream.getTracks()[i].stop()
      }
    }
  }
    
  sendAudioFile = file => {
    this.stopAudio(this.recordingStream)
    const formData = new FormData();
    formData.append('chatDocument', new File([file], 'audio.wav', { type: 'audio/wav' }));
    this.chatService.uploadChatDocument(formData).subscribe((res: any) => {
      var date = new Date()
      var data = {
        userId: localStorage.getItem("Id"),
        name: localStorage.getItem('UserName'),
        message: res.data.chatFile,
        date: date,
        type: 'audio'
      }
      this.messages.push(data);
      var newdata = {
        userId: this.chatId,
        messages: this.messages,
        currentUser: localStorage.getItem("Id")
      }

      this.chatService.sendMessage({message: res.data.chatFile, type: 'audio', roomName: this.chatId, userId: localStorage.getItem("Id"), data: newdata}, cb => {
        console.log("ACKNOWLEDGEMENT ", cb);
      });
    })
  };
}
