<div class="chat-root">
  <div class="chat-parent">

          <div class="messages-wrapper">
              <div class="instructions">
                Please send questions related to USMLE curriculum only, questions regarding subscriptions or technical issues are sent to our Facebook page or our page WhatsApp number.
                <br>Your questions will be answered within 2 business days.
                <br>It would be better to attach questions id with your question to better help you.
              </div>
              <div class="messages-header">
                  <h3>Instructor</h3>
              </div>
              <div class="chat-area-wrapper" #scrollMe>
                  <div  *ngFor="let message of messages; let i = index;">
                    <div style="position: relative;">
                      <div class="ai_tag" *ngIf="message.isAIBot">
                        <img src="../../assets/images/ai_stars.svg">
                        <span>{{ message.isReferenceLink ? 'Generated from Reference Materials' : 'Generated from AI Assistant' }}</span>
                      </div>
                      <div class="reciever_wrapper chat_text" *ngIf="message.userId != id && message.type == 'text'" [innerHTML]="message.message"></div>
                    </div>
                    <div class="reciever_wrapper chat_text" *ngIf="message.userId != id && message.type == 'text' && message.isAIBot && !message.askAdmin && !message.isReferenceLink && i === messages.length - 1">
                      <span>Not satisfied with the AI Assistant results? Click on the button to forward the query to Instructor.</span><br>
                      <button class="background" (click)="forwardQueryToAdmin()">Ask Instructor</button>
                    </div>
                    <div class="reciever_wrapper chat_text" *ngIf="message.userId != id && message.type == 'file'" >
                      <div style="display: flex; align-items: center; font-weight: bold;">
                        <i class="pi pi-paperclip" style="margin-right: 7px;"></i>
                        <a style="text-decoration: none; color: #7B8793;" pTooltip="View Document" href="{{message.message}}" target="_blank">File</a>
                      </div>
                    </div>
                    <div class="reciever_wrapper chat_text" *ngIf="message.userId != id && message.type == 'audio'" >
                      <div style="display: flex; align-items: center; font-weight: bold;">
                        <i class="fa fa-microphone" style="margin-right: 7px; font-size: 16px;"></i>
                        <a style="text-decoration: none; color: #7B8793;" pTooltip="Play Audio" href="{{message.message}}" target="_blank">Audio</a>
                      </div>
                    </div>
                    <div class="chat_text sender_wrapper" *ngIf="message.userId == id && message.type == 'text'" [innerHTML]="message.message"></div>
                    <div class="chat_text sender_wrapper" *ngIf="message.userId == id && message.type == 'file'" >
                      <div style="display: flex; align-items: center; font-weight: bold;">
                        <i class="pi pi-paperclip" style="margin-right: 7px;"></i>
                        <a style="text-decoration: none; color: white;" pTooltip="View Document" href="{{message.message}}" target="_blank">File</a>
                      </div>
                    </div>
                    <div class="chat_text sender_wrapper" *ngIf="message.userId == id && message.type == 'audio'" >
                      <div style="display: flex; align-items: center; font-weight: bold;">
                        <i class="fa fa-microphone" style="margin-right: 7px; font-size: 16px;"></i>
                        <a style="text-decoration: none; color: white;" pTooltip="Play Audio" href="{{message.message}}" target="_blank">Audio</a>
                      </div>
                    </div>
                    <div class="chat_text sender_wrapper d-flex" *ngIf="fileUploading && i === messages.length - 1">
                      <span>File Uploading</span>
                      <div class="loader"></div>
                    </div>
                    <div class="chat_text sender_wrapper d-flex" *ngIf="audioUploading && i === messages.length - 1">
                      <span>Audio Uploading</span>
                      <div class="loader"></div>
                    </div>
                    <div class="reciever_wrapper chat_text" *ngIf="failedQuery && i === messages.length - 1">
                      <span>Sorry we can’t help you with this query. Your query has been forwarded to your Instructor. You will be notified shortly.</span>
                    </div>
                    <div class="reciever_wrapper chat_text" *ngIf="message.askAdmin && !failedQuery && i === messages.length - 1">
                      <span>Your query has been forwarded to your Instructor. You will be notified shortly.</span>
                    </div>
                  </div>
              </div>
              <div class="ai_loader" *ngIf="aiLoader">
                <span>Generating a response...</span>
              </div>
              <div class="input-area-wrapper" [ngClass]="aiLoader || fileUploading || audioUploading ? 'disabled' : ''">
                  <div class="input-wrapper">
                      <textarea rows="1" cols="1" id="text_chat" [(ngModel)]="userText" [disabled]="demo == '-1'" placeholder="Enter your message here" (keydown.enter)="sendChat()"></textarea>
                  </div>
                  <div class="send-btn-wrapper">
                    <input type='file' id="getdoc" style="display:none" accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx" (change)="onFileUpload($event)">
                    <button class="mr-2" onclick="document.getElementById('getdoc').click()"  [disabled]="demo == '-1'">
                      <i class="pi pi-paperclip"></i>
                    </button>
                    <button class="mr-2" *ngIf="!recording" (click)="initiateRecording()"  [disabled]="demo == '-1'">
                      <i class="fa fa-microphone"></i>
                    </button>
                    <button class="recording mr-2" *ngIf="recording" (click)="stopRecording()"  [disabled]="demo == '-1'">
                      <i class="fa fa-microphone"></i>
                    </button>
                    <button [disabled]="userText == ''" (click)="sendChat()">
                      <i class="pi pi-send"></i>
                    </button>
                  </div>
              </div>
          </div>
      </div>
  </div>

<p-toast></p-toast>