import { Component, OnInit } from '@angular/core';
import { FlashcardService } from '../shared/services/flashcard.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-ai-flashcard-module',
  templateUrl: './ai-flashcard-module.component.html',
  styleUrls: ['./ai-flashcard-module.component.scss'],
  providers: [MessageService]
})
export class AiFlashcardModuleComponent implements OnInit {
  module = 'browse'
  courses: any = []
  selectedCourse = null;
  aiDecks = true;
  aiDecksGenerated = false;
  aiLoader = false;
  aiText = 'AI Deck';
  aiDecksAvailable = false;
  
  constructor(private flashcardService: FlashcardService, private messageService: MessageService) { }

  ngOnInit(): void {
    if(window.innerWidth>750){
      document.getElementById('eup-navbar-heading').innerText = 'Flashcards';
    }else{
      document.getElementById('eup-navbar-heading').innerText = '';
    }
    this.courses = JSON.parse(localStorage.getItem('subscriptions'))
    if (this.courses.length > 0) {
      this.selectedCourse = this.courses[0].courseID
    }
  }

  generateAIDecks() {
    this.aiDecksGenerated = false;
    const data = {
      userId: localStorage.getItem('Id'),
      courseId: this.selectedCourse
    }
    this.aiLoader = true;
    this.flashcardService.generateAIDecks(data).subscribe(async (res: any) => {
      try {
        if (res.status) {
          this.aiLoader = false;
          this.aiDecksGenerated = true;
          this.messageService.add({severity:'success', summary:'AI Deck Generated', detail:'Your AI deck and flashcards are generated successfully.'});
        } else {
          this.messageService.add({severity:'error', summary:'AI Generation Failed', detail: res.message ? res.message : 'Something went wrong while generating AI statistics!'});
        }
        this.aiLoader = false;
      } catch (e) {
        this.aiLoader = false;
        this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
      }
    }, (error: any) => {
      this.aiLoader = false;
      this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
    })
  }

  checkAIDecks(event: any) {
    if (event > 0) {
      this.aiDecksAvailable = true;
    }
  }
}
